import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "The Heart of the Builder",
  "date": "2016-07-30T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Sep Kamvar offers a `}<a parentName="p" {...{
        "href": "http://farmerandfarmer.org/mastery/builder.html"
      }}>{`very intelligent argument against user-centered design in this article`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The danger in user-centered design is that it releases the designer of the responsibility for having a vision for the world. Why have one when we can just ask users what they want? But this is a very limiting mindset. The user sees the world as it is. Our job as builders is to create the world as it could be.`}</p>
    </blockquote>
    <p>{`I’m still undecided about where I fall on this spectrum. I do see the benefits of both approaches. But maybe user-centered design isn’t about asking what the user wants, but instead looking at the `}<em parentName="p">{`problems`}</em>{` the user face and then use your powers to bridge the gap between your vision and reality through design. I am, once again, reminded of this Henry Ford quote:`}</p>
    <blockquote>
      <p parentName="blockquote">{`If I had asked people what they wanted, they would have said faster horses.`}</p>
    </blockquote>
    <p>{`I can’t help but think that while Ford knew his customers were wrong about their self-proclaimed desires, he understood their fundamental need for a better, faster way of transportation. And that’s the an important part of user-centered design.`}</p>
    <p>{`History has seen many great ideas fail because they were introduced at the wrong time or were designed in a vacuum. Combining user-centered design to a strong vision of the future might just be a good way to guard against that.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      